var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { css } from 'emotion';
export var breadcrumbsContainer = function breadcrumbsContainer(theme) {
  return css(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n    display: flex;\n    height: 100%;\n    justify-content: flex-start;\n    align-items: center;\n    margin-left: ", ";\n    margin-right: ", ";\n"])), theme.spacing.sm, theme.spacing.sm);
};
export var breadcrumbsList = function breadcrumbsList(theme) {
  return css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n    display: flex;\n    height: 100%;\n    justify-content: flex-start;\n    align-items: center;\n    list-style-type: none;\n    position: relative;\n    z-index: ", ";\n"])), theme.zIndex.dropdown - 100);
};
export var breadcrumbsItem = function breadcrumbsItem(theme) {
  return css(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n    & + & {\n        margin-left: ", ";\n    }\n"])), theme.spacing.sm);
};
export var breadcrumbsCurrentItem = function breadcrumbsCurrentItem(theme) {
  return css(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n    position: relative;\n    &:before {\n        content: '';\n        position: absolute;\n        top: -4px;\n        left: -4px;\n        width: calc(100% + 8px);\n        height: calc(100% + 8px);\n        border: 2px solid ", ";\n        border-radius: ", ";\n        opacity: 0.25;\n    }\n"])), theme.colors.formInputBorderActive, theme.border.radius.md);
};
export var breadcrumbsBtn = function breadcrumbsBtn(theme) {
  return css(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n    padding-left: ", ";\n    padding-right: ", ";\n"])), theme.spacing.sm, theme.spacing.sm);
};
export var breadcrumbsControl = function breadcrumbsControl(theme) {
  return css(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n    min-width: 150px;\n"])));
};
export var notUsableContainer = function notUsableContainer(width, height) {
  return css(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: ", "px;\n    height: ", "px;\n"])), width, height);
};